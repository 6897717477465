import { createContext } from "react";

const LoginContext = createContext({
  userLoginStatus: false,
  toggleLoginStatus: (state: boolean) => {}
});
export const LoginProvider = LoginContext.Provider;
export const LoginConsumer = LoginContext.Consumer;

export default LoginContext;
