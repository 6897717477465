import { AppConstants } from "@/constants";

export const getOrganisationName = () => {
  const localStorageData = localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS);
  if (localStorageData) {
    const userDeatils = JSON.parse(localStorageData);
    return userDeatils.organistionDetails.name;
  }
  return AppConstants.EMPTY_STRING;
};

export const getOrganizationId = () => {
  const localStorageData = localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS);
  if (localStorageData) {
    const userDeatils = JSON.parse(localStorageData);
    return userDeatils.organistionDetails.id;
  }
  return AppConstants.EMPTY_STRING;
};

export const getUserName = () => {
  const localStorageData = localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS);
  if (localStorageData) {
    const userDeatils = JSON.parse(localStorageData);
    return userDeatils.userName;
  }
  return AppConstants.EMPTY_STRING;
};

export const getManagerTags = () => {
  const localStorageData = localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS);
  if (localStorageData) {
    const userDeatils = JSON.parse(localStorageData);
    return userDeatils.managerTags;
  }
  return AppConstants.EMPTY_STRING;
};

export const labelForGA = `${getOrganisationName() + " " + " | " + " " + getUserName() + " " + " | " + " " + getManagerTags()}`