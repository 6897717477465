const myLogger = (type: any) => (...args: Array<any>) => {
  if (process.env.NODE_ENV !== "production") {
    (console as any)[type](...args);
  }
};

export const mLog = (...args: Array<any>) => {
  myLogger("log")(...args);
};

export const mWarn = (...args: Array<any>) => {
  myLogger("warn")(...args);
};
