let base_url = process.env.REACT_APP_BASE_URL,
  graphqlUrl = process.env.REACT_APP_GRAPHQL_BASE_API,
  clientId = process.env.REACT_APP_CLIENT_ID,
  reactGAId = process.env.REACT_APP_GA_TRACKING_ID,
  ChatAppId=process.env.REACT_APP_CHAT_SSUP_ID,
  downloadService = process.env.REACT_APP_DOWNLOAD_SERVICE_URL;

const Url_Config = {
  base_url: base_url,
  graphqlUrl: graphqlUrl,
  clientId: clientId,
  reactGAId: reactGAId,
  ChatAppId:ChatAppId,
  downloadService: downloadService,
  cleverTapAccountId:(process.env.NODE_ENV === "production"?"485-5Z5-866Z":"TEST-585-5Z5-866Z")
};

export default Url_Config;
