import gql from "graphql-tag";

export const sendUserPasswordResetEmail = gql`
  mutation sendUserPasswordResetEmail($id: UUID!) {
    sendUserPasswordResetEmail(id: $id)
  }
`;

export const sendUserPasswordResetEmailStaffing = gql`
mutation SendUserPasswordResetEmailStaffing($id: UUID!) {
  SendUserPasswordResetEmailStaffing(id: $id) {
   success
  }
}

`;

export const resetPassWord = gql`
  mutation createUserPassword(
    $email: String!
    $hash_code: String!
    $client_id: String!
    $new_password: String!
    $browser_details: BrowserDetails
  ) {
    createUserPassword(
      email: $email
      hash_code: $hash_code
      client_id: $client_id
      new_password: $new_password
      browser_details: $browser_details
    ) {
      id
      first_name
      last_name
      gender
      date_of_birth
      age
    }
  }
`;

export const resetPasswordStaffing = gql`
mutation CreateUserPasswordStaffing($payload: CreateUserPasswordStaffingInput!) {
  CreateUserPasswordStaffing(payload: $payload) {
   success
  }
}
`;

export const LOGOUT_CLEAR_CACHE = gql`
  mutation clearusercachedata($id: String!) {
    clearusercachedata(id: $id) {
      success
    }
  }
`;

export const USER_LOGOUT = gql`
  mutation UserLogout {
    logout 
  }
`