import * as CookieManager from "@/service/cookie-manager";
import { gql } from "apollo-boost";

import { AppConstants } from "@/constants";
import { graphQLClientMutationHelper } from "./graphql-helpers";

import { getDomainName } from "@/utils/browser-utils";
import { deepGet } from "@/utils";

const REFRESH_AUTH = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshOauthToken(refresh_token: $refreshToken) {
      token
      expires
      __typename
    }
  }
`;
const login = (userName: string, password: string) => {};

const setAuthToken = (
  token: string,
  isRefreshToken: boolean = false,
  options?: object
) => {
  const { AUTH_JWT_COOKIE_NAME, AUTH_REFRESH_TOKEN } = AppConstants;
  let key = isRefreshToken ? AUTH_REFRESH_TOKEN : AUTH_JWT_COOKIE_NAME;

  CookieManager.setCookie(key, token, {
    domain: getDomainName(),
    ...options,
  });
};

const getAuthToken = () => {
  return CookieManager.getCookie(AppConstants.AUTH_JWT_COOKIE_NAME);
};

const checkForAuth = () => {
  const { AUTH_JWT_COOKIE_NAME, AUTH_REFRESH_TOKEN } = AppConstants;
  let authCookie = CookieManager.getCookie(AUTH_JWT_COOKIE_NAME),
    refreshToken = CookieManager.getCookie(AUTH_REFRESH_TOKEN);
  return !!authCookie && !!refreshToken;
};

const checkForPageViewPermissions = (path: any) => {
  const { USER_PERMISSIONS } = AppConstants;
  const permissionRequiredPages = [
    "payroll",
    "employees",
    "compliances",
    "announcements",
  ];
  let permissionsCookie = CookieManager.getCookie(USER_PERMISSIONS) || "";
  if (!permissionsCookie) {
    return true;
  }
  let permissionArray = JSON.parse(permissionsCookie);
  let pathName = path.split("/")[1];
  pathName = pathName.substring(0, pathName.length);
  let permissionRequired = "view_" + pathName;
  if (permissionRequiredPages.includes(pathName)) {
    return permissionArray.includes(permissionRequired);
  } else return true;
};

const logOutUser = () => {
  // let domain = getDomainName();
  // CookieManager.removeCookie(AppConstants.AUTH_JWT_COOKIE_NAME, {
  //   domain,
  // });
  removeAppCookies();  
};

const removeAppCookies = () => {
  let cookies = document.cookie.split(";");
  let domain = getDomainName();
  for(let i = 0;i<cookies.length;i++){
    let cookie = cookies[i];  
    let pos = cookie.indexOf("=");
    let cookieVal = pos > -1 ? cookie.substr(0, pos) : cookie;
    if(Object.values(AppConstants).indexOf(cookieVal.trim()) > -1){
      CookieManager.removeCookie(cookieVal.trim(), {
        domain,
      });
    }
  }
}

const getFreshAuthToken = async () => {
  const refreshToken = CookieManager.getCookie(AppConstants.AUTH_REFRESH_TOKEN);
  const { data, error } = await graphQLClientMutationHelper({
    mutation: REFRESH_AUTH,
    variables: { refreshToken },
    context: {
      headers: {
        skipAuthorization: true,
      },
    },
  });
  if (data && Object.keys(data as any).length > 0) {
    let getAuthDetails = deepGet(data as any);
    return getAuthDetails("refreshOauthToken") || AppConstants.EMPTY_OBJECT;
  } else {
    throw error;
  }
};

export {
  login,
  logOutUser,
  checkForAuth,
  checkForPageViewPermissions,
  getAuthToken,
  setAuthToken,
  getFreshAuthToken,
  removeAppCookies
};
