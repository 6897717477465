import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";

const sept22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1rO7AiP8Dz7-XHTu01PRyJFkpKVNBjRlJ"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1qPM_LRvTe8QSiI3K8WP8plh0OLwebCG8/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1XkzQHcTgFQinzk-5H0fkv09XzFNcP4AE/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1ovht_qKdq5bVJDzF-CMhe--qGxovofxj"
  }
];

const oct22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1ZHGSYCfGO_txQatqYp7DmAMh0rF8hkpo"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1UPwkMMebQXrPUaUCMDBdJFpb6Hd12ikW/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1FBdUQSLKNYlcZnOBFFwYK95JCEQcYBGj/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Od3_m-873bDowtJe6Ze3oyCT2Mwz1ASN"
  }
];

const Nov22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1tipBtguQ94bcdPfsMWGM-l1SxWhIV4p4"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1q0N256oY8rJZkQ9yAs3QLN-UQgp28LoJ/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1O6Vs_ajNVncwas3GmisGgt9EUNzfLOIt/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1LMm5Pn_qQz3DDwA9utKrkaHqspCBXHEO"
  }
];

const Dec22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1SlH2VPS9ETiF6xQt5duscncUFuVLj46O"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1IDDLZGMhv7bs-urghtmPgoZRTD1RyCCU/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1TZo2TbBkRFU_4TJNF56w4t8S2Dhh7PmY/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1d17wdQcNKVC1_6z94_vqtpUhnJa6IDKi"
  }
];

const Jan23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1imgj5LC8K03BrJsU_m_fhFxY3HK_eDoA"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1FnTCUTPuCagxUbEPbxjSAVtGRQoTL8sf/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1hGa93RoWsfFs4H44MikKm3aPisAfwXVW/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1bB5LYXWP_TavnImYwVpZliJCR0wRRVT2"
  }
];

const march23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1vBJY1O36AOJZqn_IooUTAYupPjSmWTKZ"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1712Lh0E3yzLaw2UjYPiZcouJqYq4fYkm/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1F8JjNeV_slLUUaVg9GSEcS8n56Ru5q8_/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1EnGsYh7tI9KCYP5PnO5MyIVa0MbYKvT4"
  }
];

const april23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/15HVM83Sx9nnRa6PQVPafhjLe8tTYZoDo"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/17Is1QJ7AqF-bHxNUhcN9oNQYK7R9blex/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1ks9Bw02g5b4rDJrJ3zLtuN8GCZPmpviq/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1jK6Qh-NdTYulLkDE66nBb1KkjRvcERPN"
  }
];
const DelhiveryRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
        
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={sept22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={march23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={april23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default DelhiveryRegisters;
