import gql from "graphql-tag";

export const GET_MONTHLY_PAYOUTS = gql`
  query GetMonthlyPayouts($filter: PayslipFilter!) {
    nodes(
      type: Payslip
      filter: { Payslip: $filter }
      aggregations: { employer_monthly_aggs: true }
    ) {
      aggregations
    }
  }
`;
export const GET_EMPLOYEES_PAYSLIPS = gql`
  query GetEmployeePayslips(
    $filter: PayslipFilter!
    $sort: String!
    $query: String
    $page: Int
    $perPage: Int
  ) {
    nodes(
      type: Payslip
      filter: { Payslip: $filter }
      query: $query
      sort: $sort
      first: $perPage
      after: $page
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        ... on Payslip {
          id
          employee {
            client_employee_id
            first_name
            last_name
            employee_code
            designation
            branch
            state {
              name
            }
          }
          disbursed
          month
          total_incentives
          total_gross
          total_employer_contribution
          total_deductions
          net_paid
          ctc
          days_paid
          pdf
          components_grouped
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_LOCATIONS = gql`
  query GetEmployeeLocations {
    nodes(
      type: Employee
      aggregations: { job_locations: { terms: { field: "branch" } } }
      first: 0
    ) {
      aggregations
    }
  }
`;

export const GET_DOCUMENTS = gql`
  mutation GetPayslipDownLoad($filter: JSON!) {
    downloadPayslips(filter: $filter)
  }
`;

export const GET_MANAGERS_DATA = gql`
  query getManagerList {
    managerList {
      me {
        id
        type
        manager_tag
        user_id
        organization_id
        user {
          id
          first_name
          last_name
          photo
          email
          mobile
        }
        organization {
          id
          name
          popular_name
        }
      }
      is_primary_manager
      managers {
        id
        type
        manager_tag
        user_id
        organization_id
        user {
          id
          first_name
          last_name
          user_type
          photo
          email
          mobile
        }
        organization {
          id
          name
          popular_name
        }
      }
    }
  }
`;

export const USER_PERMISSIONS = gql`
  query getUserPermission($id: String!) {
    getUserPermission(id: $id) {
      user_id
      permissions {
        id
        name
        operation {
          id
          name
        }
        object {
          id
          name
        }
      }
    }
  }
`;

export const REMOVE_MANAGER = gql`
  mutation deleteManager($id: Int!) {
    deleteManager(id: $id) {
      managers {
        id
        type
        manager_tag
        user_id
        organization_id
        user {
          id
          first_name
          last_name
          email
          mobile
        }
        organization {
          id
          name
          popular_name
        }
      }
    }
  }
`;

export const ADD_PERMISSION = gql`
  mutation createUserPermission(
    $object: String!
    $operation: String!
    $user_id: String!
  ) {
    createUserPermission(
      payload: { object: $object, operation: $operation, user_id: $user_id }
    ) {
      user_id
      permissions {
        id
        name
        operation {
          id
          name
        }
        object {
          id
          name
        }
      }
    }
  }
`;

export const REMOVE_PERMISSION = gql`
  mutation deleteUserPermisssion($user_id: String, $permission_id: Int) {
    deleteUserPermission(
      payload: { user_id: $user_id, permission_id: $permission_id }
    ) {
      user_id
      permissions {
        id
        name
        operation {
          id
          name
        }
        object {
          id
          name
        }
      }
    }
  }
`;
