import gql from "graphql-tag";

export const UserDetailsQuery = gql`
  query {
    me {
      first_name
      last_name
      entity {
        id
        name
      }
    }
  }
`;

export const GET_MANAGERS_DATA = gql`
  query getManagerList {
    managerList {
      me {
        id
        type
        manager_tag
        user_id
        organization_id
        user {
          id
          first_name
          last_name
          photo
          email
          mobile
        }
        organization {
          id
          name
          popular_name
        }
      }
      is_primary_manager
      managers {
        id
        type
        manager_tag
        user_id
        organization_id
        user {
          id
          first_name
          last_name
          user_type
          photo
          email
          mobile
        }
        organization {
          id
          name
          popular_name
        }
      }
    }
  }
`;

export const getUserDetailsQuery = gql`
query GetManagerDetails {
  employerManager {
    id
    type
    manager_tag
    user_id
    organization_id
    user {
      id
      first_name
      last_name
      entity_id
    }
    organization {
      id
      name
      popular_name
    }
  }
}
`;


export const getAttendanceUserDetails = gql`
query GetReportingManagerDetails($payload: ReportingManagerDetailsInput) {
  GetReportingManagerDetails(payload: $payload) {
    id
    user_id
    organization_id
    user {
      id
      first_name
      last_name
    }
    organization {
      id
      name
      popular_name
    }
  }
}
`;