const ColorConstants = {
  WHITE: "#ffffff",
  DARK_BLACK: "#262626",
  GUNMETAL: "#53565b",
  LIGHT_GRAY: "#fafafa",
  GRAY: "#bfbfbf",
  BLUE: "#020812",
  DARK_BLUE: "#1890ff",
  LIGHTBLUE: "#e4eefc",
  RUPEE_COLOR: "#4c4e51"
};

export default ColorConstants;
