import gql from "graphql-tag";

export const analyticsCardQuery = gql`
  query HraDashboard($payload: HraDashboardInput!) {
    getHraDashboardData {
      activeHeadCount(payload: $payload) {
        data {
          value
          month
        }
      }
      newJoinings(payload: $payload) {
        data {
          value
          month
        }
      }
      attrition(payload: $payload) {
        data {
          value
          month
        }
      }
      salaryCost(payload: $payload) {
        data {
          value
          month
        }
      }
      absenteeism(payload: $payload) {
        data {
          value
          month
        }
      }
      grievanceMetric(payload: $payload) {
        data {
          avg_tat
          resolution_percentage
          month
          avg_tat_change
        }
        start_month
        end_month
      }
    }
  }
`;

export const getLatestPayslipMonth = gql`
  query PayslipMonth($organization_id: UUID!) {
    getLatestPayslipMonth(organization_id: $organization_id) {
      month
    }
  }
`;

export const searchJobRole = gql`
  query searchJobRole($query: String!, $organization_id: UUID!) {
    searchJobRole(query: $query, organization_id: $organization_id) {
      job_role
    }
  }
`;

export const recriutmentQuery = gql`
  query HraNewJoinings($payload: HraCommonFilterInput!) {
    getHraRecruitmentData {
      joiningAndSelection(payload: $payload) {
        data {
          month
          selection
          joining
        }
      }
      joiningDrop(payload: $payload) {
        data {
          reason
          value
        }
      }
      selectionToJoining(payload: $payload) {
        data {
          month
          value
        }
      }
    }
  }
`;

export const maleToFemaleRatio = gql`
  query HraHeadCountGraphs($maleToFemaleRatioInput: HraCommonFilterInput!) {
    getHraHeadCountData {
      maleToFemaleRatio(payload: $maleToFemaleRatioInput) {
        total
        male
        female
        na
      }
    }
  }
`;

export const attritionMetricsData = gql`
  query HraLeftJobData($payload: HraCommonFilterInput!) {
    getLeftJobData {
      leftJobAndJoining(payload: $payload) {
        data {
          month
          left_job
          joining
        }
      }
      leftJobFrequency(payload: $payload) {
        lte_7days
        lte_15days
        lte_30days
        gt_30days
      }
    }
  }
`;

export const USER_PERMISSIONS = gql`
  query getUserPermission($id: String!) {
    getUserPermission(id: $id) {
      user_id
      permissions {
        id
        name
        operation {
          id
          name
        }
        object {
          id
          name
        }
      }
    }
  }
`;

export const PRIMARY_MANAGER = gql`
  query getPrimaryManagers {
    getPrimaryManagers {
      id
      type
      manager_tag
      user_id
      organization_id
      user {
        id
        first_name
        last_name
        email
        mobile
        photo
      }
      organization {
        id
        name
        popular_name
      }
    }
  }
`;

export const LOGGED_ON_DATE = gql`
  query getEmployerLoginDetails($user_id: ID!) {
    getEmployerLoginDetails(user_id: $user_id) {
      first_logged_on
    }
  }
`;

export const typeOfQueries = gql`
  query HraDashboardGraphs($payload: HraCommonFilterInput!) {
    getGrievanceMetric {
      grievanceTypeOfQueries(payload: $payload) {
        PF
        ESIC
        total
        Salary
        Others
      }
    }
  }
`;
