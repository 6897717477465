import getApolloClient from "./init-apollo";

export const clientHelper = (operationType: any) => async (argObject: any) => {
  let data = null,
    apolloClient = getApolloClient();

  try {
    data = await apolloClient[operationType](argObject);
  } catch (error) {
    return { data, error };
  }

  if (!data || !data.data) {
    return {
      data,
      error: { message: "Empty Data", networkError: { message: "Empty Data" } }
    };
  }

  return { data: data.data, error: null };
};

export const graphQLQueryHelper = async (argObject: any) => {
  const args = { fetchPolicy: "no-cache", ...argObject }; // Disable cache by default when performing a manual query
  return await clientHelper("query")(args);
};

/* Please prefer graphQLMutationHelper instead of this function whenever possible */
export const graphQLClientMutationHelper = async (argObject: any) => {
  const { data, error } = await clientHelper("mutate")(argObject);

  if (error) {
    return { data: {}, error };
  }

  return { data, error: null };
};

export const graphQLMutationHelper = (mutate: any) => async (
  argObject: any
) => {
  let data = null;

  try {
    data = await mutate(argObject);
  } catch (error) {
    return { data, error };
  }

  if (!data || !data.data) {
    return { data, error: { networkError: "Empty Data" } };
  }

  const actualData = Object.values(data.data)[0]; //access for example {data: {userPasswordLogin: {data: []}}}

  return { data: actualData, error: null };
};

export const getGraphQLErrorMessage = (errorObj: any) => {
  if (errorObj.graphQLErrors && errorObj.graphQLErrors.length > 0) {
    return errorObj.graphQLErrors[0].message;
  } else {
    return errorObj.networkError.message;
  }
};

export const getGraphQLErrorCode = (errorObj: any) => {
  if (errorObj.graphQLErrors && errorObj.graphQLErrors.length > 0) {
    return errorObj.graphQLErrors[0].extensions.code;
  } else {
    return "NETWORK_REQUEST_FAILED";
  }
};
