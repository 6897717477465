import { MutationResolvers, QueryResolvers } from "@/generated/graphql";
import { getCount } from "@/gql-queries";
import { mLog } from "@/utils/logger";

export const resolvers = {
  Mutation: {
    setCount: (parent, { count }, { cache }, info) => {
      let data;
      try {
        // if query does not exist in the cache it will throw an error
        data = cache.readQuery<any>({
          query: getCount
        });
      } catch {}
      cache.writeData({
        data: {
          count: data.count + count
        }
      });
      return count;
    },
    setEmpGlobalList: async (parent, args, { cache }, info) => {
      // try {
      //   // if query does not exist in the cache it will throw an error
      //   data = cache.readQuery<any>({
      //     query: getCount
      //   });
      // } catch {}
      try {
        cache.writeData({
          data: {
            empData: args
          }
        });
      } catch (e) {}
      mLog(cache);
      return null;
    }
  },
  Query: {
    getCount: (parent, args, { cache }) => {
      const { count } = cache.readQuery<any>({
        query: getCount
      });

      return count;
    }
  }
} as {
  Mutation: MutationResolvers;
  Query: QueryResolvers;
};
