import { AppConstants } from "@/constants";
import { getGraphQLErrorCode } from "@/service/graphql-helpers";
import { ApolloError } from "apollo-boost";
import notification from "antd/es/notification";

const { TOKEN_EXPIRED, UNAUTHENTICATED } = AppConstants;

export const isTokenInvalidOrExpired = (
  networkError: any,
  graphQLErrors: any
) => {
  let graphQLErrorCode = getGraphQLErrorCode({ graphQLErrors });
  return !!(
    networkError &&
    networkError.statusCode === 400 &&
    graphQLErrors &&
    (graphQLErrorCode === TOKEN_EXPIRED || graphQLErrorCode === UNAUTHENTICATED)
  );
};

export const handleErrors = (
  description?: string,
  error?: ApolloError | undefined,
  duration: number = 2
) => {
  if (error && error.graphQLErrors) {
    description = error.message;
  }
  notification.error({
    message: AppConstants.ERR_MSG,
    description: description,
    duration: duration,
    top: 80
  });
};
