import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";

const janData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1vl4Hh8EnTCGltY4AeLWctssMTELF3yvh/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/101TLbBQdZIz65v46eQEP-HP3uwvlycGM/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1KAa9UHet8XZQisNRC3a0renyxrCTAbGV/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1clKCRcAnQHajYfcYTBGe-C2OhgKCtjsB/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1xYqj_Cfame3pV4p0sy3YBIPY2isXE1eM/view?usp=sharing"
  }
];
const febData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1HRRWXKD6Lmz_r2R3UNA8IKzmuqPJk-m5/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1h6dh2ImvVoBYt-vqFbDw0FgaUvrLx030/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1ho6QECgEA-SeW407Z5zYdo6W2tJdnIgH/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1y4QoIQWBLDZlf2atgHNSBlbC0M2QhiCz/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1XszeOYfckhTHkzNWOD5UzGXCchaxrKp5/view?usp=sharing"
  }
];
const marchData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/17FbgdjXrJUWdBFeCrIOmO8OM-LgBk_SQ/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1ZYhJWSYNC6BSPeimcYLcD-7CZmWio6We/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1AaFCCmdX7jY6xQhCnAVm-0bpyJRZeLGr/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/16jgwmk2EffV_lvLxduP24i3Gnkb9oJH3/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1Iin6JXP6kXvo7z6nuG2HHx-bQXQF_whH/view?usp=sharing"
  }
];
const aprilData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1hI1wS9_Zy_Mn15FE-oHrgzSRbvqK9Kv0/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1lJfxpMyH_SmL6GNicRn-9__xwTE_8rjy/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1k2aG1ALujIngt8Nd3bsyMCr3x2GzdRQO/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/19HXR9dTuWr0Tv-8bbdH8nHYnG6bnq7DQ/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1gYaIBQvQXFyVpXiFtE_zaVyprBKR9-cU/view?usp=sharing"
  }
];
const mayData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1n0SFAwcoWTSx0mX1ZCkCBy4F13JGIVRx/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1NVHjUnDNtu_Jr6BrEqDwEkMudxak0nRi/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1dPXbCo1j9RtXz-_t1BJYS8DMwFUYVJJZ/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1J9teg92qVui76LIIhUc3ieBhumZvnJcc/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1Vcq12wDF7tN8IoBzqx-PgJ-DL5yhDj_K/view?usp=sharing"
  }
];

const juneData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1Owp0xHwtfYAwRgQaCwsrLtwKBo2cGt-G/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1xdCswZMVBciBbaq6xCEbHtIVU8EH4h5T/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/18RMlJ3KvfuEaZY97a7pxpyAG8BYXaOKh/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/10Ek5DTANvBDi6vTlx9ZGgPQKglSfLIuo/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1edV0yXApaKSUF85vZ4eGv-7vhfQga_Ks/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1u5chWs0ZLlsQp2xLjlWvUSsDURfw0_QY/edit?usp=sharing&ouid=103922496311680714758&rtpof=true&sd=true"
  }
];

const julyData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1NPXidYtX6CyNSGLEZcl92cwANXDvVW7p/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1Dj6Cnec4-dPLYKSsDVyxSLvQj-ZAYTBO/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/12RsXU4f4jrvNS4bl73QRggdZR1E1FCEw/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1q-F8Z5eJlxE7tI2_6DkNbhrqrtET2Yre/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1stgU7AmTAFI2zOsbbEQGSv9ZDEonSG-z/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1h6VPBB-LdYu6JyWvqXKg_vyKdCyysGfD/edit?usp=sharing&ouid=103922496311680714758&rtpof=true&sd=true"
  }
];

const augustData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1KcMqyw7KzlPkjhIbVpEaE-8v_aH3bhEd/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1lvEGiqUowJR90oTTVKVQ7kJuEnN0l76A/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1E_PE-bXYWIrvlMJDgNF7mMUvf-lVxIRE/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/16h6Kgpej-GKu-3E3rGiXSdBUec06Aukn/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1AQR54dyvVlV_Jc21S0zRgMez0fA8kD5n/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1mlCjgYbfPzffEgRtfKek_Bduyz_bf1Ti/edit?usp=sharing&ouid=115186378417739308335&rtpof=true&sd=true"
  }
];

const septemberData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/12Zakpo3IuYIRmscqwsXssHi0aSfQZfCC/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1LxNyD4BJ7qtk6f5HWJLNNVhUwlLRMNr8/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1hryHeGugXUfKz6QHeseangfkRi_eqtgQ/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1MiK4zenRBzLK_VnWd4F07Jicp0VtYkf0/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1HQmBYEMOFHk1WR6F4WtKn91fiamiGroN/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1gxbMiRAhFc2_KaPHqme5PPdcoxTyZ7qfMGSanwcdelE/edit?usp=sharing"
  }
];

const octoberData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1nIa4pvbFMp_HepkZ2iZMwaqduPZgs1Et/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1AFV7nQ4Jq6AtE1-RH1Izey1Wr_53jb_v/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1rHORlexn2EGVtKO01npQieQOP-OfYNOO/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1UiquVyDivZtqtWefLaKmvUPoxK0Q_ZrB/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1PqnJ9BJTxLBkNLXKMn6iyOncEbKlF0uY/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1LO6LhbsDjsP4oOnb8PzZHfmqTyVvC170/edit?usp=sharing&ouid=110588581833793062716&rtpof=true&sd=true"
  }
];

const NovemberData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1r56nzAiQwKbqJHxLgbYYx2butvr87cGC/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1LMy8gijhaGzuj1wGTP2gTPw2LCa0-tuk/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1iiwq-oXx4sMh9zOWgWcFbJxe7uUV_00f/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1UlwvdaFDkgA1rHuyvsJyu3SRcZrperwi/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1JtF3UgOC-NmsL7esXCoInFqXT0XY2brm/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1qhr9NCEXkJ1q5MpmeOuuiinViDGLWCru/edit?usp=sharing&ouid=110588581833793062716&rtpof=true&sd=true"
  }
];

const DecemberData = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1LkL_elFCKe_55o_87_TSwfXzP4KRsjFR/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1b-trW6jP0hIj9yE1Q9mVrwtNVOWUa-UC/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1tYbGM3BXgHK7dac1hYCcV51pucZr5VJI/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1lmDqPGmjMy4cEwDyPpbLnjbL18AUVWQX/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1BAlaS8LZ3GVW6uMCcHJLlzuDCQSr_J2b/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/194zSchgJ2ApOVI9D-6Lok4mPYVTw4WkV/edit?usp=sharing&ouid=110588581833793062716&rtpof=true&sd=true"
  }
];

const January22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1Ua94yMYfhcYsxh5l6HBkItJ6LdQU5fT_/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1yshPzvDvLhvDd05aAsP_bk0diVE3aiXK/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1Sl7JEi1uMJb5CWl5RsRl5s8Uykfc8EgJ/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1ZPDb8A6GTThUzZxXRjRetds5qIKICFRJ/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1vwvMJBPUbt207C7lKov5ih76ggizUvGr/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1OdAkEARA0d4SzuGNMGCrkAoymRrCcSY0/edit?usp=sharing&ouid=110588581833793062716&rtpof=true&sd=true"
  }
];

const feb22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1UljIvL-8VvvT0Arp1XgdsKrIuK_QcIx4/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/15a-44mNqzYo0FY9Z3taMccGra3K-RZqo/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1U7oKDe0G9TNsBoNpWtwrVPPuJKvi9Gfv/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1sY-tc1oHM8xsVH4zY-rSFyjxKbn5gMEA/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1K71O-1qYAkwAGQwx0HMxBbKKo5KCvEAU/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1BQnrKkwsRLrT3i9RJG49A1rrlMlokb9Q/edit?usp=sharing&ouid=110588581833793062716&rtpof=true&sd=true"
  }
];

const march22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1sjCD6f71MQrOs2ySpkQmM1o8MQsOVNEm/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1fcoqu4ncI_paC_qcjVHhmjh-fyBuNocJ/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1LOyESsHgfWG-7lawQScqnhh5U5KFXPJl/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1YtV2NiusNtgSRZ6jWHe00f0pSRafBL32/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1ChzNMB2dFg8fvPE5HyPyabt_7xd7jiOt/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1oFqhHb5BZ9jl45Su7f9EB55iO6f0UIEs/edit?usp=sharing&ouid=102343176037640294726&rtpof=true&sd=true"
  }
];

const april22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1Q_wPTpxHw7yfVvotwWKCM_FZrHDfq4f2"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/16thtGy_WwVjuUWFhgcIwOMvUXIzPoryj/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1v27geQESyeQpwAbOcHHUgqz_5kVpV_bU/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/19O2VEKPxaVoX4B_ds70NSAxy7dVdpgFB/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1ChzNMB2dFg8fvPE5HyPyabt_7xd7jiOt/view"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1oFqhHb5BZ9jl45Su7f9EB55iO6f0UIEs/edit#gid=1510543312"
  }
];

const may22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/11SM6GRwggySy81zYf3KqAzYZlUM0REf6/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/127EHpusRuKshKEPhLyHuGhpm-W_ysygb/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1rxQXOjU-o7H5yIT2wW8v2kxJ5_B8jLjI/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1gEWF-NAY68lbVOYU3DR_77X1ZtVVf714/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1LGy-x_PThX44NBa89gid3qc7p3q7m2zV/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/12FPjy4OUXwKiHYEZiL5DPGI-5w6GPOA4/edit?usp=sharing&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const june22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1AOn5NFgLZQGWcEs4MtN2NTilqiK7ulLy/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/14MJMGmbCPGb_uWaMgorjeG5VrJoOnuDx/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1MOlCDXnEuBCezZtJvX62ckAYw5bCd_-M/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1UoJkvKtQypQVYKzltvxfPE--93rKxETm/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1AbzcLjp8m5l6_vUp3qKFouw5YR3GntzZ/view"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1BtFwCauIxEsY3niFV0lfUEWInYbQZrIK/edit?usp=sharing&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const july22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1XAKR0SZSualiGDrwSn69g6jZKAbwE1ix/view?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1GI8lIB6kTDR30WTVmdANbvhSOxsYMrSQ/view?usp=sharing"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1kYWtezksTA7d86cnMlUMwiBauvpUBofF/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1_E8lKEfCzN1DOdVRrocaU87IpvoOhbKr/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1-oAbGfz5nG1gLv_Ap1q8VZnBUfixAnwL/view?usp=sharing"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1mOIGe7P72WGlvTfifappvLnJ6X4iJfhe/edit?usp=sharing&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const aug22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1zca_tQwUL2BnNum4WjhJoJCLB-B9jmQP/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1AnNb8_GWPTheY_1OBDWwgpKESnfKrYzF/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1MD3eHOZubSpAQ1HpmffFRj9Xppk94h3m/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1N7R5ccREuEMEY-c3LPHO3KXwVrnBoHSE/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1he-dyiiEqgvyjLIwYI5xu995iQLW291y/view"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/18YhEvS5ZuEN22daYKeGBF6IQrM46xM4x/edit?usp=sharing&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const sept22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1g_QCADpZOg6X6Muv7MjUqFeybpYFJqzw/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1k2sWp3s9NwtbfSH4CZ-33ZD4-giShSYv/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1mqhfR6bXs5tVYJ505OrT7dKruOiOhu9O/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1GtLPr4bwyXLUsue2fmEh4Cie_AC0480M/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1fpw2_uXWjXlTvLX0Swp1T1AitiJveWtd/view"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1F2weoGwc5UJr3t1tVviSPbIFF2OId0kl/edit?usp=sharing&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const oct22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1OTgFkCw5U6TRjOfteIXf2e2gkBIcwkLP?usp=share_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1kJeGvkLBAp8uhaWMFZ3rYRqbPaa-t1WF/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/184TxCI6GpGscet_tPLC4cUX83FssTzLM/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1H5Nre2coGqp8gU7y9at-XOpFMoVGnXaU/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1cwgv4de5So1bWpymV1PzgnDQ2PbbPGdK?usp=share_link"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1jgliH78Amj_BaoFZAQa2M33et6qfPkFR/edit?usp=share_link&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];

const nov22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/file/d/1cJBZolVbSKHwSVL9bm_baoTpg5Jtex6G/view"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1-VyOXzsNaONeDfuprbwtNijh-VMOlES9/view"
  },
  {
    title: "ESIC ECR",
    url:
      "https://drive.google.com/file/d/1Sy5VYgc8nZfTBeorPua6haXBmvXwF1f0/view"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1tFJufffghxn-80KfVnHBMIJGU-r58zt5/view"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/file/d/1w--woyY2kaKVElRXof2QF8BJVCShlYpr/view"
  },
  {
    title: "Employee Master data",
    url:
      "https://docs.google.com/spreadsheets/d/1bWPL2vJ1QfkwDskf6giJElFBnYdbVX9U/edit?usp=share_link&ouid=112892395291807982919&rtpof=true&sd=true"
  }
];


const DelhiRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={janData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={febData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={marchData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={aprilData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={mayData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={juneData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={julyData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={augustData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={septemberData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={octoberData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={NovemberData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2021
              </div>
            }
            footer={null}
            bordered
            dataSource={DecemberData}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={January22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={feb22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={march22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={april22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={may22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={june22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={july22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={aug22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={sept22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default DelhiRegisters;
