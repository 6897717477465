import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
/*eslint-disable*/

export const NavigationWrapper = styled.div<{
  sticky?: boolean;
  loggedIn?: boolean;
}>`
  /*eslint-enable*/
  display: flex;
  flex: 1;
  width: 100%;
  height: 60px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
    `};
  ${({ loggedIn }) =>
    loggedIn &&
    css`
      & ${MenuContainer} {
        justify-content: flex-end;
      }
    `};
`;

export const NewSpan = styled.span`
  display: flex;
  padding-top: -8rem;
  padding-bottom: 0rem;
  height: 14px;
  width: 26px;
  color: #0c63db;
  font-family: Usual;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding-left: 4px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  height: 100%;
`;

export const NavigationContainer = styled.div`
  width: 100%;
  min-width: 1024px;
  padding: 0 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface AnchorProps {
  to: string;
}
export const LogoContainer = styled(Link)`
  cursor: pointer;
  margin-left:21px;
  margin-right:89px;

`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

interface ImageProps {
  src: string;
  alt?: string;
}

export const Image = styled.img<ImageProps>`
  width: 120px;
`;

interface LinkProps {
  active?: boolean;
  to: string;
  activeClassName?: string;
  key?: string;
}

export const SpanLink = styled.span`
color: #FFFFFF;
`

export const NavLinkItem = styled(NavLink) <LinkProps>`
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 100%;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  background: #fff;
  text-decoration: none;
  &.active {
    color: rgba(0, 0, 0, 1);
    background: #f2f2f2;
  }
  &:hover {
    background: #f2f2f2;
  }
`;

export const NavItem = styled.a`
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 100%;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  background: #fff;
  text-decoration: none;
  &.active {
    color: rgba(0, 0, 0, 1);
    background: #f2f2f2;
  }
  &:hover {
    background: #f2f2f2;
  }
`;

export const ProfileNameWrapper = styled.div`
padding: 8px;
font-size: 14px;
width: 120px;
overflow: hidden;
display: inline-block;
text-overflow: ellipsis;
white-space: nowrap;
`;
