import React, { Suspense, useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";

import Navigator from "@/common/Navigator";
import CircularLoader from "@/common/Loader";
import { PrivateRoute } from "@/routes/PrivateRoute";

import { LoginProvider } from "@/common/Login/LoginContext";
import history from "@/history";

import ReactGa from "react-ga";
import Url_Config from "@/config/url_config";
import { ContainerWrapper } from "@/common/elements";
import { isPublicPage } from "./publicPage";
import LivspaceRegisters from "@/pages/public/livspaceRegisters";
import DelhiRegisters from "@/pages/public/delhiRegisters";
import AmazonRegisters from "@/pages/public/amazonRegisters";
import DelhiveryRegisters from "@/pages/public/delhiveryRegisters";
import WholeSaleRegisters from "@/pages/public/wholeSaleSolutionRegisters";
import UdaanRegisters from "@/pages/public/udaanRegisters";

const Dashboard = React.lazy(() => import("@/pages/dashboard"));
const Invoice = React.lazy(() => import("@/pages/invoice"));
const Settings = React.lazy(() => import("@/pages/settings"));
const Payroll = React.lazy(() => import("@/pages/payroll"));
const Compliances = React.lazy(() => import("@/pages/compliances"));
const Employees = React.lazy(() => import("@/pages/employees"));
const Announcements = React.lazy(() => import("@/pages/announcements"));
const Analytics = React.lazy(() => import("@/pages/analytics"));
const Grievance = React.lazy(() =>
  import("@/pages/analytics/components/analyticsgraphs/grievancemetrics")
);
const Recruitment = React.lazy(() =>
  import("@/pages/analytics/components/analyticsgraphs/recruitmentmetrics")
);
const Headcount = React.lazy(() =>
  import("@/pages/analytics/components/analyticsgraphs/headcountmetrics")
);
const Attrition = React.lazy(() =>
  import("@/pages/analytics/components/analyticsgraphs/attritionmetrics")
);

const AttendanceReport = React.lazy(() => import("@/pages/attendance/report"));

const Landing = React.lazy(() => import("@/pages/landing"));
const Error404 = React.lazy(() => import("@/pages/Error/Error404"));
const Error403 = React.lazy(() => import("@/pages/Error/Error403"));
const Error500 = React.lazy(() => import("@/pages/Error/Error500"));
const Error400 = React.lazy(() => import("@/pages/Error/Error400"));
const ResetPassword = React.lazy(() => import("@/common/Login/components/ResetPassword.component"));
const ManageRM = React.lazy(() => import("@/pages/attendance/attendance-mapping-rm"))
const Requests = React.lazy(() => import("@/pages/attendance/requests"));
const Configure = React.lazy(() => import("@/pages/attendance/configs"));
const ExitModule = React.lazy(() => import('@/pages/attendance/exit-module'))


const Main: React.FC = () => {
  const [userLoginStatus, toggleLoginStatus] = useState(false);
  const loginContextState: any = {
    userLoginStatus,
    toggleLoginStatus
  };
  console.log(window.location.pathname);
  const trackingId = Url_Config.reactGAId as string;
  ReactGa.initialize(trackingId);

  if (isPublicPage(window.location.pathname)) {
    return (
      <div
        style={{
          display: "flex",
          background: "#f0f2f7",
          width: "100%",
          minHeight: "100vh",
          margin: "0"
        }}
      >
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/livspace-registers"
                component={LivspaceRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/delhi-registers"
                component={DelhiRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/amazon-registers"
                component={AmazonRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/delhivery-registers"
                component={DelhiveryRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/lotswholsale-registers"
                component={WholeSaleRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
        <Router history={history}>
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route
                exact
                path="/public/pages/udaan-registers"
                component={UdaanRegisters}
              />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }

  return (
    <div
      style={{
        display: userLoginStatus ? "flex" : "block",
        background: "#f0f2f7",
        width: "100%",
        minHeight: "100vh",
        margin: "0"
      }}
    >
      <Router history={history}>
        <LoginProvider value={loginContextState}>
          <Navigator />
          <Suspense fallback={<CircularLoader />}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/compliances" component={Compliances} />
              <PrivateRoute exact path="/payroll" component={Payroll} />
              <PrivateRoute exact path="/invoice" component={Invoice} />
              <PrivateRoute path="/employees" component={Employees} />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/analytics/summary" component={Analytics} />
              <PrivateRoute path="/analytics/grievance" component={Grievance} />
              <PrivateRoute path="/analytics/headcount" component={Headcount} />
              <PrivateRoute
                path="/attendance/reports"
                component={AttendanceReport}
              />
              <PrivateRoute path="/attendance/configure" component={Configure} />
              <PrivateRoute path="/attendance/managerm" component={ManageRM} />
              <PrivateRoute path="/attendance/requests" component={Requests} />
              <PrivateRoute path="/exit-requests" component={ExitModule} />
              <PrivateRoute path="/announcements" component={Announcements} />
              <PrivateRoute path="/analytics/attrition" component={Attrition} />
              <PrivateRoute
                path="/analytics/recruitment"
                component={Recruitment}
              />
              <Route path="/password/reset" component={ResetPassword} />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/500" component={Error500} />
              <Route exact path="/400" component={Error400} />
              <Route path="*" component={Error404} />
            </Switch>
          </Suspense>
        </LoginProvider>
      </Router>
    </div>
  );
};

export default Main;
