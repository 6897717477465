import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from "apollo-boost";
import { ApolloLink, Observable } from "apollo-link";
import ApolloLinkTimeout from "apollo-link-timeout";
import { withClientState } from "apollo-link-state";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

import { resolvers } from "@/gql-resolvers/resolvers";
import Url_Config from "@/config/url_config";
import {
  getAuthToken,
  getFreshAuthToken,
  setAuthToken,
  logOutUser,
} from "@/service/auth-service";
import { mLog } from "@/utils/logger";
import { AppConstants } from "@/constants";
import { isTokenInvalidOrExpired } from "@/utils/apollo-utils";
import { createUploadLink } from "apollo-upload-client";

interface IRefreshToken {
  token: string;
  expires: number;
}

interface IRequestHeaders {
  "X-AJClient": string;
  "X-AJ-Platform": number;
  Authorization?: string;
}

let apolloClient: any = null;

const { ERRORS, EMPTY_STRING } = AppConstants;

// const getHttpLink = () => {
//   return new HttpLink({
//     uri: Url_Config.graphqlUrl,
//     credentials: "same-origin",
//   });
// };

const uploadLink = createUploadLink({
  uri: Url_Config.graphqlUrl,
  credentials: "same-origin",
});

const getTimeoutLink = () => new ApolloLinkTimeout(30000);

const getErrorLink = (options?: any) => {
  const { history } = options;
  return onError(({ networkError, graphQLErrors, operation, forward }) => {
    mLog(graphQLErrors);

    if (isTokenInvalidOrExpired(networkError, graphQLErrors)) {
      return new Observable((observer) => {
        // Fetching refresh token
        getFreshAuthToken()
          .then((tokenInfo: IRefreshToken) => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            const { token, expires } = tokenInfo;
            setAuthToken(token, false, { expires });
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            mLog("Error while fetching Refresh token", error.message);
            logOutUser();
            history.push(ERRORS.E_500.ROUTE);
            observer.error(error);
          });
      });
    } else if (
      /**
       * Issue with networkError: Error in apollo-link-state. Type Error doesnot have statusCode field defined
       * For reference :- https://github.com/apollographql/apollo-link/issues/300
       * */
      networkError &&
      "statusCode" in networkError
    ) {
      if (networkError.statusCode === 404) {
        history.push(ERRORS.E_404.ROUTE);
      } else if (networkError.statusCode === 500) {
        history.push(ERRORS.E_500.ROUTE);
      } else if (networkError.statusCode === 400) {
        history.push(ERRORS.E_400.ROUTE);
      }
    }
  });
};

const getAuthLink = () => {
  return setContext(async (_: any, { headers }: { [key: string]: any }) => {
    const { skipAuthorization = false } = headers || {
      skipAuthorization:
        _.operationName === "RecogniseUser" ||
          _.operationName === "UserLogin" ||
          _.operationName === "RefreshToken"
          ? true
          : false,
    };
    const requestHeaders: IRequestHeaders = {
      "X-AJClient":
        _.operationName === "sendUserPasswordResetEmail"
          ? "DesktopWeb.Payroll"
          : "DesktopWeb.Employer",
      "X-AJ-Platform": 1,
    };

    if (!skipAuthorization) {
      const token = getAuthToken();
      requestHeaders.Authorization = token ? `Bearer ${token}` : EMPTY_STRING;
    }
    return { headers: requestHeaders };
  });
};

const getStateLink = () => {
  return withClientState({
    cache,
    defaults: {},
    resolvers: resolvers,
  });
};

const cache = new InMemoryCache();

const createApolloClient = (
  initialState?: NormalizedCacheObject,
  options?: object
) => {
  return new ApolloClient({
    cache: cache.restore(initialState || {}),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    link: ApolloLink.from([
      getStateLink(),
      getTimeoutLink(),
      getErrorLink(options),
      getAuthLink(),
      uploadLink,
    ]),
    connectToDevTools: true,
  });
};

const getApolloClient = (
  initialState?: NormalizedCacheObject,
  options?: object
) => {
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, options);
  }

  return apolloClient;
};

export default getApolloClient;
