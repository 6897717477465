const publicPages = [
  "/public/pages/delhi-registers",
  "/public/pages/delhi-registers/",
  "/public/pages/livspace-registers",
  "/public/pages/livspace-registers/",
  "/public/pages/amazon-registers",
  "/public/pages/amazon-registers/",
  "/public/pages/delhivery-registers",
  "/public/pages/delhivery-registers/",
  "/public/pages/lotswholsale-registers",
  "/public/pages/lotswholsale-registers/",
  "/public/pages/udaan-registers",
  "/public/pages/udaan-registers/"
];

export const isPublicPage = (pathname: string) => {
  if (publicPages.includes(pathname)) {
    return true;
  }

  return false;
};
