import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";

const Oct22Data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1tkrYG5lYsB8JiG_rXSiMp-lU2B9t46hQ?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1--EPABlYGjDeTpOdhL5H_pCePaRTKHmM?usp=sharing"
  },
  {
    title: "Indusage Techapp Pvt Ltd.",
    url:
      "https://drive.google.com/drive/folders/176xWUnuPcJJUgm5Q_gyVqK27z3PMRWHl?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1681-3iQ5c8QeUnXXWBc2VlF6_Q1Rsfvx?usp=sharing"
  }
]
const Dec22Data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/115fTuXFVIHn6eP1H4bGX-P534fj-2eeU?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1GlwiwXYuKGVyesra5olqkHseaIa6MxpI?usp=sharing"
  },
  {
    title: "Indusage Techapp Pvt Ltd.",
    url:
      "https://drive.google.com/drive/folders/1maiAq7CBqXz63WEJh_OY_tFf9ildau2s?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/17rqShyIG2aQ1BTVU_2POpxFkPu48ZGn2?usp=sharing"
  }
  ,
  {
    title: "Employee Master Excel",
    url: "https://docs.google.com/spreadsheets/d/1a57XUHohk0n7semj-jofQb3d3b4F0JIq/edit?usp=sharing&ouid=107517622804139371371&rtpof=true&sd=true"
  }
];

const Nov22Data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1Yg7YNFq8-pkTAtS0IERlC_KOPCKe7PCE?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1H_ZqOSqOW6H1CHkbqyWfNJ_7k_4ZPR8e?usp=sharing"
  },
  {
    title: "Indusage Techapp Pvt Ltd.",
    url:
      "https://drive.google.com/drive/folders/1fhaLq9nXIWLhMs2qQovq9gnvi6NIjVG7?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1kdPp8o4oQjUmoztcwT88cc3zQoIl-5h6?usp=sharing"
  }
];

const Jan23Data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/19cufcgc-lzcP3146eDE83UiCM6YQFieQ"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1pMTJaiXivovlpgpfMkg49TM0d7-brq1T"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1p0wAI9l_jnD9sp0hziURBOZUo5PnZslo"
  }
  ,
  {
    title:"Indusage Techapp Private Limited",
    url:"https://drive.google.com/drive/folders/1qjFm2F5rafbRqfoi7fCOuTzaXf7ejLtq"
  }
];

const feb23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1AyKk79QUp4CejakeRTgfK-6KOjaYu-j0"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1QlTsMZjQC4-vTnamkS-6dnLWf1hYuc_3"
  },
  {
    title: "Indusage Techapp Pvt Ltd.",
    url:
      "https://drive.google.com/drive/folders/1-aID4xroV-1cr0tH-C5KrdztkLo7Fcyk"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1Woy6O3amfAj8RP1lMs6lpJLbZJK_xDE-"
  }
  ,
  {
    title: "Employee Master Excel",
    url: "https://docs.google.com/spreadsheets/d/1PXnFQcrtuWl4pcd9Tsrj9TQp_gKRSHKr/edit?rtpof=true&sd=true"
  }
];

const march23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1sCPGrOjlsQrioOrRePYTLioF05mEZdxp?usp=share_link"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1VwnXNx1jjVv4zZ7j4yzpas9pxIsVDDGT?usp=share_link"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1sKMXGE4UG-OYtORkMWFnTU8-YTvYJfN-?usp=share_link"
  }
];

const april23Data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1Wcj8JC-8aLN-uaqB2lpgey8PhQ487OP2"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1jS_PduOiU-0MWARY7fmGmM06J5yLtTxD"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/10imTAI3PyWQfYTqOG7cL1vs2Qq8yMKig"
  }
];

const may23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1K9ii9X9UajmsYXEJJajwJdwvP4n2PedI"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1TxIpartidEuMgqU2Se75b8h2rhgKeuil"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1i5e_aLdY1dBNuF4PYiX4Z9fMoHiuH6oF"
  }
  ,
  {
    title:"Employee Master Excel",
    url:
    "https://docs.google.com/spreadsheets/d/1d-t1Fp6_9VK2zXngQNDeUj4-jKjATD1X/edit?rtpof=true&sd=true"
  }
];

const june23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1ZYe6vlicfEGSwscXjaLnecp0b178u4l9?usp=drive_link"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1uGxSD6g2sv2ogIAjUqjrt1csKFEjo4TX?usp=drive_link"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1o4DdLBA4pzKn21povHibUzCaftaibgLq?usp=drive_link"
  }
];

const july23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1GhZP0Epu3ZtBNiaQLfYOsjQuUJmmQe3m?usp=drive_link"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1FT6q7fUbU6gL1aC2UV6Vpgtiyo_2-99y?usp=drive_link"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1h1vpMEKQyJT0uhgC0TjNWjoK20OjsheU?usp=drive_link"
  }
];

const aug23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1WACCwTeVZ1htu8-32j0t19qe_ig8fNJU?usp=share_link"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1-9JNJhuWzhd7t37fy_TX51ytZaUSiRS_?usp=share_link"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1SmlK9_aKgMYyyj8CvHdNC1x-itryXej6?usp=share_link"
  }
];

const sept23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1MDMIi-649pCYc2JFPUEQ7xWvacuecw3P?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1Lj9cDvXkMQa2cpAWcZ9N62yjMTzXNpV2?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1AXVSzpki5mzmKVcmbREddK0NAh0YFJny?usp=sharing"
  }
];

const oct23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1jEoc0IkiYtD8lj6MxpOVffOjTZMY7GXI?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1EskCjMiuX8uWgwyFIVWsvK5U6_WNZovs?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1EskCjMiuX8uWgwyFIVWsvK5U6_WNZovs?usp=sharing"
  }
];

const nov23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1fGUTob9lJYsRZypqjeT7ukc7hBsWWjeq?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1PwkctvV3-Fjq3ROzACfRJ72-kC5HY4Tk?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/10cKrDkP0PFEaOoKUllxbCp45Ohm_z8ey?usp=sharing"
  }
];

const dec23data = [
  {
    title: "Hiveloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1YxYzKx5ngbwuFAFpAgizD9f26dG_hluQ?usp=sharing"
  },
  {
    title: "Hivloop Technologies pvt ltd.",
    url:
      "https://drive.google.com/drive/folders/1TgXFrWkfsvHhG9zcp_WomyhEeLyCrkyf?usp=sharing"
  },
  {
    title: "Newloop Logistics Private Limited",
    url:
      "https://drive.google.com/drive/folders/1vrl6L_My60HWYTUT_tLW5Y1ql3r99wLA?usp=sharing"
  }
];

const UdaanRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
        
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={feb23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={march23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={april23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={may23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={june23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={july23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={aug23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={sept23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={oct23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={2}></Col>  
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={nov23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>  
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December , 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={dec23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default UdaanRegisters;
