import Url_Config from '@/config/url_config'
import clevertap from 'clevertap-web-sdk'
const account_id = Url_Config.cleverTapAccountId;
export const setUpCleverTap= () =>{
  clevertap.privacy.push({optOut: false})
  clevertap.privacy.push({useIP: false})
  clevertap.init(account_id) 
}
export const createProfile = (props:any) =>{
  clevertap.onUserLogin.push({
    "Site":{
      "Email":props.Email,
      "Identity":props.Identity
    }
  })
}

export const createEvent = (eventName,payload) =>{
  clevertap.event.push(eventName,payload)
}