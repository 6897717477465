import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import "@/index.css";
import ReactGa from "react-ga";
import * as serviceWorker from './serviceWorkerRegistration';
import "./App.css";
import getApolloClient from "@/service/init-apollo";
import { AppConstants } from "./constants";
import history from "@/history";
import Main from "@/routes";

const initialState = AppConstants.EMPTY_OBJECT;

// Will pass initial state  and config like redirect etc to client creator //
let apolloClient: any = getApolloClient(initialState, { history });

// const initData = () => {
//   apolloClient.writeData({
//     data: {
//       __typename: "EmpData",
//       count: 0
//     }
//   });
// };

// initData();

// // client.resetStore()
// apolloClient.onResetStore(async () => {
//   initData();
// });
// // client.clearStore()
// apolloClient.onClearStore(async () => {
//   initData();
// });

history.listen(location => {
  ReactGa.set({ page: location.pathname }); // Update the user's current page
  ReactGa.pageview(location.pathname); // Record a pageview for the given page
});

serviceWorker.register();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ApolloHooksProvider client={apolloClient}>
      <Main />
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
