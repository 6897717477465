import React, { useContext, useEffect } from "react";
import { logOutUser } from "@/service/auth-service";
import { useMutation } from "react-apollo-hooks";
import LoginContext from "../LoginContext";
import { withRouter, RouteComponentProps } from "react-router";
import emptyImage from "@/assets/images//empty_icon.png";
import styled from "styled-components";
import { AppConstants } from "@/constants";
import { LOGOUT_CLEAR_CACHE,USER_LOGOUT } from "./mutation";
import { mLog } from "@/utils/logger";
import { notification } from "antd";
import { getAuthToken } from "@/service/auth-service";
const Logout: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  const { toggleLoginStatus } = useContext(LoginContext);
  const [logoutUser, { data }] = useMutation(LOGOUT_CLEAR_CACHE);
  const [userLogout] = useMutation(USER_LOGOUT);
  const variables = {
    id: localStorage.getItem(AppConstants.USER_ID)
  };
  useEffect(() => {}, []);
  const logOutHandler = () => {
    const logoutPayload = {
      context:{
        "x-aj-platform": 1,
        "authorization": `Bearer ${getAuthToken()}`
      }
    }
    toggleLoginStatus(false);
    userLogout(logoutPayload).then((response:any) => {
      logoutUser({ variables })
      .then((response: any) => {
        logOutUser();
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_USERDETAILS);
        localStorage.removeItem(AppConstants.USER_PERMISSIONS);
        localStorage.removeItem(AppConstants.USER_ID);
        localStorage.removeItem(AppConstants.PRIMARY_MANAGER);
        localStorage.clear();
        history.push("/");
        window.location.reload();
      })
      .catch(error => {
        notification.error({
          message: error.message
        });
      });
    })
  };
  const LogoutContainer = styled.a`
    color: rgba(0, 0, 0, 0.75);
    margin: -5px -12px;
    padding: 5px 12px;
  `;
  return (
    <LogoutContainer onClick={logOutHandler}>
      <img src={emptyImage} style={{ paddingRight: "12px" }} alt={""} />
      Logout
    </LogoutContainer>
  );
};

export default withRouter(Logout);
