import Cookie from 'js-cookie';


export const setCookie = (key: string, value: any, options: object) => {
    let defaultExpiryTime = new Date(Date.now() + 24 * 60 * 60 * 1000);

    Cookie.set(key, value, {
        expires: defaultExpiryTime,
        secure:true,
        ...options
    })
}

export const getCookie = (key: string) => {
    return Cookie.get(key);
}

export const removeCookie = (key: string, options: object) => {
    Cookie.remove(key, options);
}