const AppConstants = {
  AUTH_JWT_COOKIE_NAME: "ajstaffing",
  USER_PERMISSIONS: "USER_PERMISSIONS",
  USER_ID: "USER_ID",
  EMAIL: "EMAIL",
  PRIMARY_MANAGER: "PRIMARY_MANAGER",
  PERMISSION_TAGS: "ajstaffing-permission",
  AUTH_REFRESH_TOKEN: "ajrefresh_token",
  LOCAL_STORAGE_USERDETAILS: "ajstaffing-userDetails",
  USER_TYPE: "EMPLOYER",
  EMPTY_STRING: "",
  EMPTY_OBJECT: {},
  EMPTY_ARRAY: [],
  UNAUTHENTICATED: "UNAUTHENTICATED",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  PERMISSION_ERROR: "FORBIDDEN",
  CTC: "Total CTC",
  HEAD: "Head Count",
  GROSS: "Gross Salary (A)",
  DEDUCTIONS: "Deductions (C)",
  OTHER_EARNINGS: "Other Earnings(B)",
  EMPLOYER: "Employer Contributions(D)",
  NET: "Net Salary(A+B-C)",
  SHOW: " Employee Salary Payments",
  EXCEL: "Download Excel",
  BREAK: "Break Up",
  ACTIVE_EMPLOYEES: "Active Employees",
  NO_DATA: "No Data",
  ERR_MSG: "ERROR",
  ERR_DES: "Unable to Fetch",
  BUT_MSG: " GO TO HOME",
  MESSAGES: {
    LOGIN: {
      VALIDATION_MESSAGES: {
        NOT_VALID_MSG: "This is not a vaild",
        REQUIRED_MSG: "Please enter your"
      },
      ERROR_MESSAGES: {
        USER_NOT_FOUND: "You don't have account with us"
      }
    }
  },
  EMAILID_REGEX: /^[-a-z0-9~!$%^&*_=+}{'?]+(.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9][-a-z0-9]*(.[-a-z0-9]+)*.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|in|global|fit|[a-z][a-z])|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
  NO_TABLE_DATA_MSG: "There are no Challans available at the moment!",
  TABLE_ERROR_MSG: "Unable to fetch records",
  ERRORS: {
    E_400: {
      ROUTE: "/400",
      HEAD: "400: Invalid or No platform detected",
      SUB_HEAD: "Something’s wrong, don’t worry we will fix it in no time!"
    },
    E_404: {
      ROUTE: "/404",
      HEAD: "404: Page Not Found",
      SUB_HEAD: "Sorry! we could not found the page you requested"
    },
    E_500: {
      ROUTE: "/500",
      HEAD: "500: Internal Server Error",
      SUB_HEAD: "Something’s wrong, don’t worry we will fix it in no time!"
    }
  },
  GOOGLE_VIEWER: {
    VIEWER_URL: "https://docs.google.com/viewer?url=",
    EMBED: "&embedded=true"
  },
  HOME_INPUT_PLACEHOLDER: "Search for Employee name or ID",
  PROVIDENT_FUND: "pf",
  EMPLOYEE_STATE_INSURANCE_CORPORATION: "esic",
  PROFESSIONAL_TAX: "pt",
  LABOUR_WELFARE_FUND: "lwf"
};

export default AppConstants;
