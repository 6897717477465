import React from "react";
import { Row, Col, Divider, List } from "antd";
import { RouteComponentProps } from "react-router";

const oct22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1-r8aFrVpkx2m0fXkPtlc9EpFL3c2wdqz?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1AfcMZx9SlVc25jKjVlXiN6joxkwPV0oW/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/14Ff1aJ8mrUL9Quuc_D9csXMSUGC0Lvrs/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1V1A9xA8_l0M8rdVu7pCxX-HgvLiexWvh?usp=sharing"
  }
];

const Nov22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1tZZ9mGi9HF_z77XwnVlukNEU47HsWSRd?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/13PbKW-SM9wQe168hnmJNr60A0TVqUH1U/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1JveFIkQgFYRIFZ8bav7f4uRu4Uzge9hn/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1n5t2wj1FN-DP-QrKfzHS1qHkCvVcBm4s?usp=sharing"
  }
];

const Dec22Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
      "https://drive.google.com/drive/folders/1EAf-aS_5me2vHdMmpj6vyjZoReRQM9za?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1u4XsUNm_RgP1c2QHl3JyK1VeqZgx7odp/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1RT3rfBL3WXoxDVyuynm1tc6oMHSrdVFN/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1AwqdEAvUip9MDvegMDQlkn4l46O0ndNp?usp=sharing"
  }
];

const Jan23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1IHh3eL-PPQcwviGo6wz4gKbH_PkP3MYU"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1kYjYAr8KSzAxpywn5-eals4gz4CobxXU/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1xJhlPdjGtV_4UiYOgroduaKnVRMoMPM5/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1loPzh5K5dZslQDoOHwz2FyefYXSes-G2"
  }
];

const Feb23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1tYyOTWbryD2VeQuZtWbzx9sNfdD4m0fN"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1E6HfJvO42m0B-MgJ8AL4aecCM5ZFOnxa/view?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1Xwu22mJK4H1aEosbAD_FWNVTegQbSmtp/view?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1krdi9FYaIPgwJk68nf__3d1MLS1HKvDI"
  }
];

const march23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1_hOQuufYYIWe2H6PHbTs0KVJLiRLY-6U"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1GgZBGi6E2z12poG82Tuc6dWmuZW2jTj7/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1SfPGMw8uB2xID-7O8qbmVS3TjPmX_4hB/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1JafCFzTNCJKS9sYcWn9hePy7-wqD6JuR"
  }
];

const april23Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/12nQuGxGgwctjHXTK66nXMH54kg8IvL2x"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1KpHwGI6IZsrZZXVGMPSoC0VXoMO7oCqP/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1d1c488RgqQ9Rwpj0VCd5LysIHbL72zXf/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1LZjjWGNInpkK18DJ9BTdF8AusLCD9BfP"
  }
];

const may23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1-UJ5n30LfThoRVBEZPVmVXGj5AWh_uzT?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1vd6L_FKSUGCxADPrAhIiI__NlDwIOXM5/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1VPkj8Kbtzbo-72V6NcReW-KeFHFaoLtL/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1-P_ctzh0UaPK6_gC353sxG0qy6bfSc9a?usp=drive_link"
  }
];

const june23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1J5zgZgPj8u9vpjDzpZXuqYHZzafxpgTM?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/18yMiNqRaOqI6GHN62HiJs2hAQnJcOIaB/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1QYry8Mvi0Fmxfr8b-UC-7MtZhoJ3Oz92/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1-blc_mZXUWGGt9hNnuWUpWADwv8S-WmO?usp=drive_link"
  }
];

const july23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1Oc4owQcq2B1m61lN2-Q_ogM4uG4wX14N?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1P92p2g0gjieFvRoQr-xfsZWsxTS6VguD/view?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1cgCiWHfmSK4mcUds4V0dyhHyGaqi7usB/view?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1v_C69DmlptouWWwXrqQn8PEuSky-SgRE?usp=drive_link"
  }
]

const aug23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1i1z1YcaH-QfPX-oPk1D8a0l2JWM3ROZH?usp=share_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/file/d/1FH4b1dOBPirex3a-w2wS4XV9GfGYaCR6/view?usp=share_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/file/d/1VQUvKaC3qIXceL6npqNo4Y-i2Z6jZxPU/view?usp=share_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1IIOGKe6W4DLgigykqYWf9gsDWFxDvj1f?usp=share_link"
  }
]

const sept23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1qu1eN0m-C1M5mCPWVGAdm7ZEPOjYT1E2?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/17HlWH3FUlmkBBbpzF972Wh1jOaaqAgkT?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1uYfac8G5RnXSjlQLeTzYdNZE-fkxIX5v?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1VED2AVHqgffAgHddy0OJ0FCP2j7k5app?usp=sharing"
  }
]

const oct23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1tLsP52OyCvqahMbez8ugQL3tMaaQNiUF?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1_RIjiYowFrdcxodycjsCtHCzr8BP245S?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1WbikxbHwXnLz4NztbO2_77kPoGIMWUvb?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1o3QN_u9TR8Ufx3O4FUpryfs2inxEcz3l?usp=sharing"
  }
]

const nov23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1E723u5UeQj4sJQ_tBIlfEM76jwK3RAEi?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1ibRT1NXNcXiU28tH5pJ3cf2dahlwRWNh?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1R5oPtAKw2CIC3r6t0StTtt40FxtuO-m8?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1o3QN_u9TR8Ufx3O4FUpryfs2inxEcz3l?usp=sharing"
  }
]

const dec23data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1Rz-dR6UTrtzZUkStboqgb4FoDqU3ZV61?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1RYvplhsDON4SWa7qmEWqPwyswSFkVFWp?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1OyVIiDv_kkLo-V5QEu21lDJcmWU2tar7?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1KR93tU-QNk1J5YbvqHOOYD6TvWyd_oho?usp=sharing"
  }
]

const jan24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1aDQ820vVxOIRY3gUq3wtKO2S58qXGqg4?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/12cASn-Zp6Fhl30M78lgPIEsroP6jSfQZ?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1b_3_nGvkXbZ7SPUIgsHv4417gnd_sahD?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1vHEbStFu8qFQhzih7ijIuyT8YOFfWP34?usp=sharing"
  }
]

const feb24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1TmSItFVtMCgA9iVpiyhKhCxQFg2Pi5re?usp=drive_link"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1i_SkkKVRJrJyhx7T4tUwp8dWVLDXZUJw?usp=drive_link"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1ELdnwCnEddpqU5JVNUWEX6BVa75tYaiS?usp=drive_link"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1AoHqSOnmE1qynK2msZdCO5onGEdK23dF?usp=drive_link"
  }
]

const march24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/10MQJEDjXuUj9izbACCVF8RjvwxTtmqfv?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1ki1hSs38Ik_NExTydwNvj43IXYvhIkyD?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1TJLNJeNl7fnnQs_XvkqBXoweh64aYFPN?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/130i2mdnx2XeZFqpC7FV8EnXEakIKCgEj?usp=sharing"
  }
]


const april24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1HXDBB053oETYdQHtZIzmy3sbOGkqCADJ?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1nIcZ4ZUQxoNnllpsklzoEGqAS4xPaA0B?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1h49Ul0B9gV-wduHRt1mW8UkGEWXYsN6p?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1Cgl7ATBfoCKHYbJN0SGKgGeZVJEw_DDL?usp=sharing"
  }
]

const may24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1M9JX6jRt0Lr3hLEkEEKz6woOyj39dMCz?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1ny2dBkIGv5dttG8hvMhoqVCc3l0eJXk4?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1Miazka39p6GHlA6TnrHlbuixfYklBr71?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/15hY9Q4svOcD2-TtwaY-OaenstNe3zwCs?usp=sharing"
  }
]

const june24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1uqdQ7fq1RO1EIkFqMd6a1Nb8WWf0fwmU?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1tsl4NxHIxpuPh56sgFN1-pTcUnlCs4ja?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1el4WCr22Rqs9Fm6RWCbOrstwf3Lh4hCt?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/14MUGMNo0ejVc-VuZi6Wq6wAgdC2yDdU1?usp=sharing"
  }
]

const july24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1QbMPXJPz9o_XaXI3F27Vp7dXH-URJyEt?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/1nogJTKxzLLAsvJtMny4CZXoJ2z3le01X?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1Ps7t9dlxjHJd2_XgC-ieRxigPd7ydrfS?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1MzhhcdYnVpMPgBjQNXTp6hnRGP-IZeq3?usp=sharing"
  }
]

const aug24Data = [
  {
    title: "Muster Roll and Wage Register",
    url:
    "https://drive.google.com/drive/folders/1K21l2oGvMwgYRbUpnJ0PSDk4ORPOu_za?usp=sharing"
  },
  {
    title: "EPF Receipt",
    url:
      "https://drive.google.com/drive/folders/12f1zybwuXopC4hbG8rgf3_LRhdXRcA3H?usp=sharing"
  },
  {
    title: "ESIC Paid Challan",
    url:
      "https://drive.google.com/drive/folders/1oqKrR1ofKH7ZZTyanSou08L8D-X5FJB7?usp=sharing"
  },
  {
    title: "Bank Transfer",
    url:
      "https://drive.google.com/drive/folders/1rFNkVXwaZdZJaiomWy5TsL3GdeTvVzhf?usp=sharing"
  }
]


const WholeSaleRegisters: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        margin: "119px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "50px"
      }}
    >
      <Row type="flex">
        <Col span={12}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/aasaanjobs-logo.png"
            }
            style={{ marginLeft: "10px", width: "125px" }}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <img
            src={
              "https://olx-notifications.s3.ap-southeast-1.amazonaws.com/resources/images/betterplace_select_logo.png"
            }
            style={{ marginLeft: "10px", width: "125px", marginTop: "-15px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "5px"
        }}
      />
        
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={oct22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November , 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Nov22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December, 2022
              </div>
            }
            footer={null}
            bordered
            dataSource={Dec22Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Jan23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                February, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={Feb23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={march23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={april23Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={may23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={june23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
        <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={july23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={aug23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                September, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={sept23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                October, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={oct23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                November, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={nov23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                December, 2023
              </div>
            }
            footer={null}
            bordered
            dataSource={dec23data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col>
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                January, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={jan24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col>  
      </Row>

      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Febuary, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={feb24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                March, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={march24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col>  
      </Row>

      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                April, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={april24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                May, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={may24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      </Row>

      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                June, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={june24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      <Col span={2}></Col>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                July, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={july24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      </Row>

      <Row>
      <Col span={11} style={{ marginTop: "30px" }}>
          <List
            size="small"
            header={
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                August, 2024
              </div>
            }
            footer={null}
            bordered
            dataSource={aug24Data}
            renderItem={item => (
              <List.Item>
                <span>
                  {item.title}
                  {" : "}
                </span>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    maxWidth: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }} rel="noreferrer"
                >
                  click here to open file
                </a>
              </List.Item>
            )}
          />
      </Col> 
      </Row>
      <Divider
        style={{
          margin: "0px",
          background: "black",
          height: "1px",
          marginTop: "37px"
        }}
      />
      <Row>
        <Col style={{ textAlign: "center", marginTop: "7px" }}>
          *This webpage is property of Aasaanjobs Pvt. Ltd.
        </Col>
      </Row>
    </div>
  );
};
export default WholeSaleRegisters;
