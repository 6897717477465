import React, { useContext } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import {
  checkForAuth,
  checkForPageViewPermissions
} from "@/service/auth-service";
import LoginContext from "@/common/Login/LoginContext";
import { mLog } from "@/utils/logger";

export const PrivateRoute: React.FC<any> = ({
  component: Component,
  ...restProps
}: any) => {
  const { toggleLoginStatus } = useContext(LoginContext);
  const isCookiePresent = checkForAuth();
  const hasPageViewPermission =
    checkForPageViewPermissions(restProps.path) || "";
  mLog("permissions", hasPageViewPermission);
  toggleLoginStatus(isCookiePresent);
  if (!hasPageViewPermission) {
    return (
      <Route
        {...restProps}
        render={(props: RouteComponentProps<any>) =>
          isCookiePresent ? (
            <Component {...props} hasPageViewPermission={false} />
          ) : (
            // <Redirect
            //   to={{ pathname: "/403", state: { from: props.location } }}
            // />
            <Redirect
              to={{ pathname: "/404", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  return (
    <Route
      {...restProps}
      render={(props: RouteComponentProps<any>) =>
        isCookiePresent ? (
          <Component {...props} hasPageViewPermission={true} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
