import React from "react";
import styled, { css } from "styled-components";
import Icon from "antd/es/icon";

const LoadingWrapper = styled.div<{ fullPageLoader: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fullPageLoader }) =>
    fullPageLoader &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}
`;

let loaderStyle = {
  color: "#000",
  fontSize: "40px"
};
interface props {
  styles?: object;
  fullPageLoader?: boolean;
}
export const CircularLoader: React.FC<props> = ({
  styles = {},
  fullPageLoader = true
}: props) => {
  loaderStyle = {
    ...loaderStyle,
    ...styles
  };

  return (
    <LoadingWrapper fullPageLoader={fullPageLoader}>
      <Icon type="loading" style={loaderStyle} />
    </LoadingWrapper>
  );
};

export default CircularLoader;
