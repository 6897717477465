import React from "react";
const deepGet = (obj: object) => (path: string) => {
  return path.split(".").reduce((acc: any, val: string) => {
    return val in acc ? acc[val] : null;
  }, obj);
};

const getMonthDropdownData = (monthList: Array<any> = []): Array<any> => {
  return monthList.map(monthString => {
    let [yearPart, monthPart] = monthString.split("-"),
      monthName = getMonth()[parseInt(monthPart) - 1].substr(0, 3),
      year = yearPart.substring(2);
    return {
      value: monthString,
      displayValue: `${monthName}-${year}`
    };
  });
};

const getMonth = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
};

const createDynamicComponent = (
  module: any,
  styleprops: any,
  childprops: any
) => {
  const custommodal = React.createElement(module, styleprops, childprops);
  return custommodal;
};

const addCommas = (num: string | number) => {
  //const { num } = props;
  if (num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      //add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    return str.join(".");
  } else {
    return num;
  }
};
const computeTotalValue = (arr: any) => {
  let sum = 0;
  if (arr) {
    sum = arr.reduce(function(acc: number, currIndex: any) {
      return acc + parseFloat(currIndex.value);
    }, 0);
  }
  return sum;
};
const getElementByKey = (arr: Array<any>, key: string) => {
  let element: any;
  for (let i = 0, len = arr.length; i < len; i += 1) {
    const arrayvalue = arr[i];
    if (arrayvalue.type === key) {
      element = arrayvalue;
      break;
    }
  }
  return element;
};
const addDashInPhoneNumber = (phone: any) => {
  if (phone) {
    let f_val = phone.replace(/\D[^\.]/g, "");
    return f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  }
  return "NA";
};
export {
  deepGet,
  getMonth,
  createDynamicComponent,
  getMonthDropdownData,
  addCommas,
  computeTotalValue,
  getElementByKey,
  addDashInPhoneNumber
};
