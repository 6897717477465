import { gql } from "apollo-boost";

export const empQuery = gql`
  query empquery {
    rates(currency: "USD") {
      currency
    }
  }
`;

export const getCount = gql`
  query getCount {
    count @client
  }
`;

export const getSignedUrl =gql`
    mutation AWSSignurl($payload: AwsSignedUrlRequestPayload!){
        awsSignedUrl(payload: $payload)
    }
`