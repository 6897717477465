import React, { useContext, useState } from "react";
import {
  NavigationWrapper,
  NavigationContainer,
  NavLinkItem,
  NavItem,
  LogoContainer,
  Image,
  MenuContainer,
  NewSpan,
  ProfileNameWrapper
} from "./styledElements";
import avatar from "@/assets/images/avatar.svg";
import { withRouter, RouteComponentProps } from "react-router";
import LoginContext from "@/common/Login/LoginContext";
import { useQuery } from "react-apollo-hooks";
import logo from "@/assets/images//betterplace_logo.svg"; //olx-people-logo-dark-theme
import darkLogo from "@/assets/images//betterplace-select-logo-dark-theme.svg";
import { AppConstants } from "@/constants";
import logoutImage from "@/assets/images/logout.svg";
import compliancesImage from "@/assets/images/compliances.svg";
import analyticsImage from "@/assets/images/analytics.svg";
import analyticsHighlightedImage from "@/assets/images/analytics_highlighted.svg";
import payrollHighlightedImage from "@/assets/images/payroll_highlighted.svg";
import announcementImage from "@/assets/images/announcement.svg";
import announcementHighlightedImage from "@/assets/images/announcement-highlighted.svg";
import compliancesHighlightedImage from "@/assets/images/compliances_highlighted.svg";
import attendanceImage from "@/assets/images/attendance.svg";
import employeesImage from "@/assets/images/employees.svg";
import emptyImage from "@/assets/images/empty_icon.png";
import employeesHighlightedImage from "@/assets/images/employees_highlighted.svg";
import payrollImage from "@/assets/images/payroll.svg";
import product from "@/assets/images/products.svg";
import { getUserDetailsQuery, getAttendanceUserDetails} from "./queries";
import {GET_MANAGERS_DATA} from "../../../src/pages/settings/queries"
import { getLatestPayslipMonth } from "../../../src/pages/analytics/queries";
import Url_Config from "@/config/url_config";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import Logout from "../Login/components/Logout";
import * as CookieManager from "@/service/cookie-manager";
import history from "@/history";
import { getOrganizationId } from "@/shared/sharedHelpers";
import {createEvent} from "../../common/cleverTap/cleverTap"
import "./index.css";

let employer_website_url = Url_Config.base_url;

export interface DropDownMenuProps {
  label: string;
  name: string;
  link: string;
  icon?: string;
  image?: string;
  rotate?: number;
  logout?: boolean;
  newTab?: boolean;
}

export const dropDownMenu: Array<DropDownMenuProps> = [
  {
    label: "Contact Us",
    name: "contact us",
    link: `${employer_website_url}/contact/`,
    icon: "phone",
    rotate: 90,
    newTab: true
  },
  {
    label: "About Us",
    name: "about us",
    link: `${employer_website_url}/about/`,
    icon: "info-circle",
    newTab: true
  },
  {
    label: "Products",
    name: "products",
    link: `${employer_website_url}/employer/products/`,
    image: product,
    newTab: true
  },
  {
    label: "Settings",
    name: "settings",
    link: "/settings",
    newTab: false,
    icon: "setting"
  },
  {
    label: "Log Out",
    name: "Log Out",
    link: "/",
    image: logoutImage,
    newTab: true,
    logout: true
  }
];

const navRightItems = [
  {
    label: "Products",
    isExternal: true,
    name: "products",
    link: `${employer_website_url}/employer/products/`
  },
  {
    label: "About",
    isExternal: true,
    name: "about",
    link: `${employer_website_url}/about/`
  },
  {
    label: "Contact",
    isExternal: true,
    name: "contact",
    link: `${employer_website_url}/contact/`
  }
];
export const Navigator: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { location } = props;
  const subKeys = ["sub1", "sub2", "sub3", "sub4", "sub5"];
  const { userLoginStatus } = useContext(LoginContext);
  const ToggletoRerender = useState(false)[1];
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const permissionTags = localStorage.getItem(AppConstants.PERMISSION_TAGS);
  const permissionTagsList = permissionTags ? permissionTags.split(",") : null;
  const { data } = useQuery(getUserDetailsQuery, {
    skip:
      !userLoginStatus ||
      !!localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS)
  });
  const { data: attendanceUserDetails } = useQuery(getAttendanceUserDetails, {
    variables: {
      payload: {
        rm_id: ""
      }
    },
    skip:
      !userLoginStatus ||
      !!localStorage.getItem(AppConstants.LOCAL_STORAGE_USERDETAILS)
  });


  const {
    data: managersData
  } = useQuery(GET_MANAGERS_DATA, {});

  const org_id = getOrganizationId();
  const {
    data: latestWageMonth,
    error: queryError
  } = useQuery(getLatestPayslipMonth, {
    variables: {
      organization_id: org_id,
    },
    skip:org_id === ""
  });
  if (location && location.pathname[location.pathname.length - 1] === "/") {
    let path = location.pathname;
    location.pathname = path.substring(0, path.length - 1);
  }
  if (permissionTagsList && permissionTagsList.includes("payroll")) {
    if (
      data &&
      Object.keys(data).length &&
      data.employerManager &&
      data.employerManager.user
    ) {
      const userDetailsObject = {
        userName:
          data.employerManager.user.first_name +
          " " +
          data.employerManager.user.last_name,
        organistionDetails: data.employerManager.organization,
        managerTags: data.employerManager.manager_tag
      };
      localStorage.setItem(
        AppConstants.LOCAL_STORAGE_USERDETAILS,
        JSON.stringify(userDetailsObject)
      );
      ToggletoRerender(true);
    }
  } else {
    if (
      permissionTagsList &&
      permissionTagsList.length === 1 &&
      permissionTagsList.includes("attendance") &&
      attendanceUserDetails &&
      Object.keys(attendanceUserDetails).length &&
      attendanceUserDetails.GetReportingManagerDetails
    ) {
      const userDetailsObject = {
        userName:
          attendanceUserDetails.GetReportingManagerDetails.user.first_name +
          " " +
          attendanceUserDetails.GetReportingManagerDetails.user.last_name,
        organistionDetails:
          attendanceUserDetails.GetReportingManagerDetails.organization,
        managerTags: ["attendance"]
      };
      localStorage.setItem(
        AppConstants.LOCAL_STORAGE_USERDETAILS,
        JSON.stringify(userDetailsObject)
      );
      ToggletoRerender(true);
    }
  }

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (subKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  //For getting the permission and displaying routes accordingly.
  const permissionList = CookieManager.getCookie("USER_PERMISSIONS");

  const localStorageObject = localStorage.getItem(
    AppConstants.LOCAL_STORAGE_USERDETAILS
  );

  const getLinkItems = () => {
    const navItems = navRightItems;
    if (location.pathname === "/" && userLoginStatus) return null;
    return navItems.map(item => {
      const { name, label, link, isExternal } = item;
      if (isExternal) {
        return (
          <NavItem key={name} href={link} target="_blank">
            {label}
          </NavItem>
        );
      }
      return (
        <NavLinkItem to={link} key={name} activeClassName="active">
          {label === "Analytics" ? (
            <label style={{ display: "flex" }}>
              Analytics <NewSpan> New </NewSpan>
            </label>
          ) : (
            label
          )}
        </NavLinkItem>
      );
    });
  };
  return (
    <>
      {!userLoginStatus ? (
        <NavigationWrapper
          sticky={true}
          loggedIn={!userLoginStatus}
          className="navigationWrapper"
        >
          <NavigationContainer>
            <LogoContainer to="/home">
              <Image src={logo} alt="Betterplace select" />
            </LogoContainer>
            <MenuContainer>{getLinkItems()}</MenuContainer>
          </NavigationContainer>
        </NavigationWrapper>
      ) : (
        <div
          style={{
            backgroundColor: "#020812"
          }}
        >
          <div
            style={{
              paddingTop: "16px",
              paddingBottom: "16px",
              position: "sticky",
              left: 0,
              width: "200px",
              right: 0,
              top: 0,
              border: "solid 1px #0f151f"
            }}
          >
            <div
              style={{
                width: "130px",
                marginLeft: "28px",
              }}
            >
              <img src={darkLogo} alt="Betterplace select" />
            </div>
          </div>
          <Menu
            mode="inline"
            theme="dark"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={[`${location.pathname}`]}
            style={{
              width: "200px",
              overflow: "auto",
              height: "80vh",
              position: "fixed",
              backgroundColor: "#020812",
              marginTop: "8px"
            }}
          >
            {permissionTagsList && permissionTagsList.includes("payroll") ? (
              <SubMenu
                style={{
                  marginTop: "20px"
                }}
                key="sub1"
                title={
                  <span>
                    <img
                      src={analyticsImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Analytics</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/analytics/summary"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/analytics/summary">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Summary</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/analytics/recruitment"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/analytics/recruitment">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Recruitment</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/analytics/headcount"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/analytics/headcount">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Headcount</span>
                  </NavLink>
                </Menu.Item>{" "}
                <Menu.Item
                  key="/analytics/attrition"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/analytics/attrition">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Attrition</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/analytics/grievance"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/analytics/grievance">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Grievance</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            ) : null}
            <SubMenu 
              style={{
                marginTop: "20px",
                marginBottom: "20px"
              }}
              key="sub5"
              title={
                <span>
                  <img
                    src={employeesImage}
                    style={{ paddingRight: "12px" }}
                    alt={""}
                  />
                  <span>Employees</span>
                </span>
              }
            >
              <Menu.Item
                key="/employees"
                style={{ paddingLeft: "0 !important" }}
                onClick={()=>{
                  if(managersData?.managerList?.is_primary_manager){
                    createEvent("Fetch_employee_for_primary_manager",{
                      Organization_ID:getOrganizationId()
                    })
                  }
                  else if(!(managersData?.managerList?.is_primary_manager)){
                    createEvent("Fetch_employee_for_secondary_manager",{
                      Organization_ID:getOrganizationId()
                    })
                  }
                }}
              >
                <NavLink to="/employees">
                  <img
                    src={emptyImage}
                    style={{ paddingRight: "12px" }}
                    alt={""}
                  />
                  <span>Data</span>
                </NavLink>
              </Menu.Item>
              {permissionTagsList && (
                <Menu.Item
                  key="/exit-requests"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/exit-requests">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Exit Requests</span>
                  </NavLink>
                </Menu.Item>
              )}

            </SubMenu>
            {permissionTagsList && permissionTagsList.includes("payroll") ? (
              <Menu.Item key="/payroll"
              onClick={()=>{
                if(managersData?.managerList?.is_primary_manager){
                  createEvent('Fetch_payroll_for_primary_manager',{
                    Organization_ID:getOrganizationId(),
                    wage_month:latestWageMonth?.data?.getLatestPayslipMonth?.month
                  })
                }
                else if(!(managersData?.managerList?.is_primary_manager)){
                  createEvent('Fetch_payroll_for_secondary_manager',{
                    Organization_ID:getOrganizationId(),
                    wage_month:latestWageMonth?.data?.getLatestPayslipMonth?.month
                  })
                }
              }}
              >
                <NavLink to="/payroll">
                  {history.location.pathname === "/payroll/" ||
                    history.location.pathname === "/payroll" ? (
                    <img
                      src={payrollHighlightedImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  ) : (
                    <img
                      src={payrollImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  )}
                  <span>Payroll</span>
                </NavLink>
              </Menu.Item>
            ) : null}
            {permissionTagsList && permissionTagsList.includes("payroll") ? (
              <Menu.Item key="/compliances">
                <NavLink to="/compliances">
                  {history.location.pathname === "/compliances/" ||
                    history.location.pathname === "/compliances" ? (
                    <img
                      src={compliancesHighlightedImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  ) : (
                    <img
                      src={compliancesImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  )}
                  <span>Compliances</span>
                </NavLink>
              </Menu.Item>
            ) : null}
            {permissionTagsList && permissionTagsList.includes("payroll") ? (
              <Menu.Item key="/announcements">
                <NavLink to="/announcements">
                  {history.location.pathname === "/announcements/" ||
                    history.location.pathname === "/announcements" ? (
                    <img
                      src={announcementHighlightedImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  ) : (
                    <img
                      src={announcementImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                  )}
                  <span>Announcements</span>
                </NavLink>
              </Menu.Item>
            ) : null}
            {permissionTagsList &&
              permissionTagsList.length === 1 &&
              permissionTagsList.includes("attendance") ? (
              <SubMenu
                key="sub2"
                title={
                  <span>
                    <img
                      src={attendanceImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />{" "}
                    <span onClick={() => console.log("Ronit")}>Attendance</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/attendance/reports"
                  style={{ paddingLeft: "0 !important" }}
                  onClick={()=>{
                    if(managersData?.managerList?.is_primary_manager){
                      createEvent('Fetch_attendance_report_for_primary_manager',{
                        Organization_ID:getOrganizationId(),
                        wage_month:latestWageMonth?.data?.getLatestPayslipMonth?.month
                      })
                    }
                    else if(!(managersData?.managerList?.is_primary_manager)){
                      createEvent('Fetch_attendance_report_for_secondary_manager',{
                        Organization_ID:getOrganizationId(),
                        wage_month:latestWageMonth?.data?.getLatestPayslipMonth?.month
                      })
                    }
                  }}
                >
                  <NavLink to="/attendance/reports">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Reports</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/attendance/requests"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/attendance/requests">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Requests</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            ) : null}
            {permissionTagsList &&
              permissionTagsList.includes("payroll") &&
              permissionTagsList.includes("attendance") ? (
              <SubMenu
                key="sub3"
                title={
                  <span>
                    <img
                      src={attendanceImage}
                      style={{ paddingRight: "8px" }}
                      alt={""}
                    />{" "}
                    <span>Attendance</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/attendance/reports"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/attendance/reports">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Reports</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/attendance/requests"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/attendance/requests">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Requests</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/attendance/managerm"
                  style={{ paddingLeft: "0 !important" }}
                  onClick={()=>{
                    createEvent('Fetch_reporting_manager_list',{
                      Organization_ID:getOrganizationId(),
                    })
                  }}
                >
                  <NavLink to="/attendance/managerm">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Manage RM</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/attendance/configure"
                  style={{ paddingLeft: "0 !important" }}
                >
                  <NavLink to="/attendance/configure">
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Configuration</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            ) : null}
            <SubMenu
              key="sub4"
              className="profile-wrraper"
              style={{
                bottom: "10px",
                position: "fixed"
              }}
              title={
                <span>
                  {userLoginStatus && localStorageObject ? (
                    <>
                      <img
                        src={avatar}
                        style={{ marginBottom: "55px" }}
                        alt={""}
                      />
                      <ProfileNameWrapper>
                        {JSON.parse(localStorageObject).userName}
                      </ProfileNameWrapper>
                    </>
                  ) : null}
                </span>
              }
            >
              {permissionTagsList && permissionTagsList.includes("payroll") ? (
                <Menu.Item key="settings">
                  <NavLink to={`/settings`}>
                    <img
                      src={emptyImage}
                      style={{ paddingRight: "12px" }}
                      alt={""}
                    />
                    <span>Settings</span>
                  </NavLink>
                </Menu.Item>
              ) : null}
              <Menu.Item key="logout">
                <Logout />
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      )}
    </>
  );
};

export default withRouter(Navigator);
